import { IconProps } from "@src/types/IconProps";

export default function CartIcon(props: IconProps)
{
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 105 105" enableBackground="new 0 0 105 105" xmlSpace="preserve">
            <path fill="#00B0F0" d="M98.21,41h-5.68L79.3,12.36c-0.75-1.62-2.36-2.65-4.14-2.65H52.64h-0.3H29.83c-1.78,0-3.4,1.03-4.14,2.65
                L12.45,41H6.79c-2.38,0-4.31,1.94-4.28,4.33c0.03,3.04,0.47,6.06,1.32,8.99l10.8,37.21c0.65,2.23,2.69,3.76,5.01,3.76h65.51
                c2.3,0,4.33-1.51,5-3.72l10.87-36.15c0.99-3.29,1.49-6.7,1.49-10.14C102.5,42.92,100.58,41,98.21,41z M38.78,77.36
                c0,2.48-2.04,4.47-4.53,4.41c-2.43-0.06-4.3-2.18-4.3-4.61c0-4.36,0-13.71,0-18.05c0-2.26,1.63-4.29,3.87-4.56
                c2.68-0.32,4.95,1.77,4.95,4.38V77.36z M56.9,77.36c0,2.48-2.04,4.47-4.53,4.41c-2.43-0.06-4.3-2.18-4.3-4.61
                c0-4.36,0-13.71,0-18.05c0-2.26,1.63-4.29,3.87-4.56c2.68-0.32,4.95,1.77,4.95,4.38V77.36z M75.03,77.36c0,2.44-1.98,4.41-4.41,4.41
                h0c-2.44,0-4.41-1.98-4.41-4.41V58.93c0-2.44,1.98-4.41,4.41-4.41h0c2.44,0,4.41,1.98,4.41,4.41V77.36z M23.83,41l8.99-21.1
                c0.37-0.87,1.22-1.43,2.17-1.43h17.35h0.3h17.35c0.94,0,1.8,0.56,2.17,1.43L81.15,41H23.83z"/>
        </svg> 
    );
}