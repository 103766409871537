import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ThemeType } from '@src/types/theme';
import cssMixin from '@styles/cssMixin';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useIsDesktop, useIsMobile } from '@src/hooks/util';
import Link from 'next/link';

const FooterInfoDataLeft = [
	{ text: '서울 종로구 종로 6 스타트업빌리지 6층' },
	{ text: 'artra@artracompany.com' },
	{ text: '070-7619-1224' },
	{ text: 'BIZ LICENSE 559-88-01794' },
	{ text: 'Communication sales business report 2023-서울종로-0950' },
];

export type FooterInfoProps = {
	footerColor?: string;
};

export default function FooterInfo(props: FooterInfoProps)
{
	const router = useRouter();
	const isMobile = useIsMobile();

	const [ fontColor, setFontColor ] = useState("#424242");

	useEffect(() => {
		if (!isMobile || router.pathname !== "/")
			setFontColor("#424242");
		else
			setFontColor("#fff");
	}, [isMobile]);
	
	return (
		<Wrapper fontColor={ props.footerColor ? props.footerColor : fontColor }>
			{ !isMobile && 
				<Col1>
					<StyledP>{"주식회사 아트라컴퍼니"}</StyledP>
					{ FooterInfoDataLeft.map((data, idx) => (
						<StyledP2 key={ `footer_${idx}` }>{ data.text }</StyledP2>
					))}
				</Col1>
			}
			<Col2>
				{ !isMobile && 
					<IconWrapper>
						<Link href="https://www.youtube.com/@ttakkung" target="_blank">
							<Circle>
								<Image
									src="/source/icons/youtube_white.png"
									width={ 27.485 }
									height={ 27.485 }
									alt="youtube"
								/>
							</Circle>
						</Link>
						<Link href="https://www.instagram.com/sorimaeul_ttakkung" target="_blank">
							<Circle>
								<Image
									src="/source/icons/instagram_white.png"
									width={ 27.485 }
									height={ 27.485 }
									alt="instagram"
								/>
							</Circle>
						</Link>
						<Link href="https://blog.naver.com/sorimaeul0403" target="_blank">
							<Circle>
								<Image
									src="/source/icons/blog_white.png"
									width={ 23.679 }
									height={ 21.217 }
									alt="blog"
								/>
							</Circle>
						</Link>
					</IconWrapper>
				}
				<div style={{ display: "flex" }}>
					<StyledP2 style={{ cursor: `url("/source/cursor_pointer.png"), pointer` }} onClick={ () => router.push("/0/1/terms") }>
						이용약관
					</StyledP2>
					<StyledP2 style={{ marginLeft: "22px", cursor: `url("/source/cursor_pointer.png"), pointer` }} onClick={ () => router.push("/0/2/policy") }>
						개인정보 보호정책
					</StyledP2>
				</div>
				<StyledP3>@ ️2023 ARTRACOMPANY. All rights reserved.</StyledP3>
			</Col2>
		</Wrapper>
	);
}

const Wrapper = styled.div<{ fontColor: string }>`
	${ cssMixin.flexRow };
	width: 100%;
	height: 100%;
	padding: 44px 25px;
	justify-content: space-between;
	color: ${ props => props.fontColor };

	${({ theme }: { theme: ThemeType }) => theme.breakPoint.mobile!`
		padding: 0;
	`}
`;

const StyledP = styled.p`
	font-size: 20px;
	line-height: normal; 
	margin-bottom: 11.74px;
`;

const StyledP2 = styled.p`
	font-size: 15px;
	line-height: normal; 

	${({ theme }: { theme: ThemeType }) => theme.breakPoint.mobile!`
		padding: 0;
		font-size: 3vw;
	`}
`;

const StyledP3 = styled.p`
	font-size: 15px;
	line-height: normal; 

	${({ theme }: { theme: ThemeType }) => theme.breakPoint.mobile!`
		padding: 0;
		font-size: 2.5vw;
	`}
`;

const Col1 = styled.div`
	${ cssMixin.flexCol };
	height: 100%;
	justify-content: space-between;
`;

const Col2 = styled(Col1)`
	align-items: end;
	${({ theme }: { theme: ThemeType }) => theme.breakPoint.mobile!`
		align-items: center;
		margin: 0 auto;
	`}
`;

const IconWrapper = styled.div`
	${ cssMixin.flexRow };
`;

const Circle = styled.div`
	width: 41.227px;
	height: 41.227px; 
	border-radius: 100%;
	background-color: ${({ theme }: { theme: ThemeType}) => theme.themeColor.backgroundSky4 };
	margin-left: 12.16px;

	img {
		position: relative;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
`;