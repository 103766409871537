import Header from "@src/components/common/header/Header";
import cssMixin from "@styles/cssMixin";
import Footer from "src/components/common/footer/Footer";
import MetaTags, { MetaTagsProps } from "src/lib/meta/MetaTags";
import styled from "styled-components"

export type CommonLayoutProps = {
	children: React.ReactNode;
	backgroundColor?: string;
    background?: string;
    headerBackground?: string;
    footerColor?: string
    footerBackground?: string;
    coloredMenu: boolean;
} & MetaTagsProps;

export default function CommmonLayout(props: CommonLayoutProps)
{
    return (
        <Wrapper>
            <CommonWrapper background={ props.background } backgroundColor={ props.backgroundColor }>
                <MetaTags { ...props } />
                <Header backgroundColor={ props.headerBackground } coloredMenu={ props.coloredMenu } />
                { props.children }
            </CommonWrapper>
            <Footer footerColor={ props.footerColor } backgroundColor={ props.footerBackground } />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    position: relative;
`;

const CommonWrapper = styled.div<{ background?: string, backgroundColor?: string }>`
    ${ cssMixin.background };
    height: 100%;
    background-color: ${ props => props.backgroundColor };
    background: ${ props => props.background };
`;