import { IconProps } from "@src/types/IconProps";
import { forwardRef } from "react";

function ArrowLeft(props: IconProps, ref: any)
{
    const width: number = props.width ? props.width : 20;
    const height: number = props.height ? props.height : 33;
    const fill: string = props.fill ? props.fill : "black";

    return (
        <div style={{ ...props.style, maxWidth: width, maxHeight: height, cursor: `url("/source/cursor_pointer.png"), pointer` }} onClick={ props.onClick } ref={ ref }>
            <svg width={ width } height={ height } viewBox="0 0 18 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.1148 31.314L1.16167 19.2526C-0.387224 17.6857 -0.387224 15.1516 1.16167 13.5847L13.1148 1.52329C14.6676 -0.0396639 17.1787 -0.0396639 18.7315 1.52329C20.2804 3.09019 20.2804 5.62396 18.7315 7.19086L9.58288 16.4186L18.7315 25.6464C19.506 26.4319 19.8932 27.4542 19.8932 28.4803L18.7315 31.314L15.9231 32.4862C14.9061 32.4862 13.8892 32.0955 13.1148 31.314Z" fill={ fill }/>
            </svg> 
        </div>
    );
}

export default forwardRef(ArrowLeft);