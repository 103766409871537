import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import cssMixin from '@styles/cssMixin';
import SignupIcon from '@src/components/icons/SignupIcon';
import LoginIcon from '@src/components/icons/LoginIcon';
import CartIcon from '@src/components/icons/CartIcon';
import { useRouter } from 'next/router';
import { useIsDesktop, useIsMobile, useIsTablet } from '@src/hooks/util';
import { ThemeType } from '@src/types/theme';
import Link from 'next/link';
import MobileMenuDrawer from '../../menu/MobileMenuDrawer';
import { ProfileIcon } from '@src/components/icons';

export type NavigationProps = {
	coloredMenu: boolean;
};

function Navigation(props: NavigationProps)
{
	const router = useRouter();
	const isDesktop = useIsDesktop();
	const isTablet = useIsTablet();
	const isMobile = useIsMobile();

	const [ loginPadding, setLoginPadding ] = useState("");
	const [ signupPadding, setSignupPadding ] = useState("");
	const [ profilePadding, setProfilePadding ] = useState("");
	const [ cartPadding, setCartPadding ] = useState("");
	const [ menuOpen, setMenuOpen ] = useState(false);
	const [ session, setSession ] = useState<any>();

	function logout()
	{
		if ((window as any).Kakao.Auth && (window as any).Kakao.Auth.getAccessToken())
		{
			if ((window as any).Kakao && !(window as any).Kakao.isInitialized())
				(window as any).Kakao.init(process.env.NEXT_PUBLIC_KAKAO_JAVASCRIPT_KEY);

			(window as any).Kakao.Auth.logout(() => {

			});
		}

		sessionStorage.removeItem("access");
		alert("로그아웃 되었습니다.");
		setSession(null);
		router.push("/");
	}

	function handleSecontBtnClick()
	{
		if (!session)
			router.push("/0/4/join");
	}

	useEffect(() => {
		setSession(sessionStorage.getItem("access"));
	}, []);

	useEffect(() => {
		if (isDesktop)
		{
			setLoginPadding("12px");
			setSignupPadding("12px 9px 10px 13px");
			setProfilePadding("9px");
			setCartPadding("11px");
		}

		if (isTablet)
		{
			setLoginPadding("9px");
			setSignupPadding("9px 5.5px 8.5px 10px");
			setProfilePadding("7px");
			setCartPadding("8px");
		}

		if (isMobile)
		{
			setLoginPadding("7.5px");
			setSignupPadding("7.5px 5.5px 7px 7.8px");
			setProfilePadding("6px");
			setCartPadding("6.8px");
		}
	}, [isDesktop, isTablet, isMobile]);

	return (
		<>
			<Wrapper>
				{ session ?
					<WhiteCircle onClick={ logout } style={{ padding: loginPadding, transform: "rotate(180deg)" }}>
						<LoginIcon />
					</WhiteCircle>
					:
					<Link href="/0/3/login">
						<WhiteCircle style={{ padding: loginPadding }}>
							<LoginIcon />
						</WhiteCircle>
					</Link>
				}
				{ (isMobile || router.pathname !== "/") &&
					<>
						<WhiteCircle onClick={ handleSecontBtnClick } style={{ padding: session ? profilePadding : signupPadding }}>
							{ session ? <ProfileIcon /> : <SignupIcon /> }
						</WhiteCircle>
						<Link href="https://smartstore.naver.com/sorimaeulttakkungis" target="_blank">
							<WhiteCircle style={{ padding: cartPadding }}>
								<CartIcon />
							</WhiteCircle>
						</Link>
					</>
				}
				{ isMobile &&
					<Menu onClick={ () => setMenuOpen(true) }>
						<svg width="24" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M24 13.75C24 14.4404 23.4404 15 22.75 15H14.25C13.5596 15 13 14.4404 13 13.75C13 13.0596 13.5596 12.5 14.25 12.5H22.75C23.4404 12.5 24 13.0596 24 13.75ZM24 7.5C24 8.19036 23.4404 8.75 22.75 8.75H8.25C7.55964 8.75 7 8.19036 7 7.5C7 6.80964 7.55964 6.25 8.25 6.25H12H22.75C23.4404 6.25 24 6.80964 24 7.5ZM22.75 0C23.4404 0 24 0.559644 24 1.25C24 1.94036 23.4404 2.5 22.75 2.5H1.25C0.559643 2.5 0 1.94036 0 1.25C0 0.559644 0.559645 0 1.25 0H22.75Z" fill={ props.coloredMenu ? "#00B0F0" : "#fff" }/>
						</svg> 
					</Menu>
				}
			</Wrapper>
			{ menuOpen &&
				<MobileMenuDrawer menuOpen={ menuOpen } setMenuOpen={ setMenuOpen } />
			}
		</>
	);
}

export default React.memo(Navigation);

const Wrapper = styled.div`
	${ cssMixin.flexRow };
	width: fit-content;
	justify-content: flex-end;
	flex: 1;
	margin-top: 18px;
	align-items: center;

	${({ theme }: { theme: ThemeType }) => theme.breakPoint.tablet`
		margin-top: 30px;
	`};

	${({ theme }: { theme: ThemeType }) => theme.breakPoint.mobile`
		margin-top: 11px;
	`};
`;

const WhiteCircle = styled.div`
	width: 50px;
	height: 50px; 
	background-color: white;
	border-radius: 100%;
	filter: drop-shadow(0px 0 10px rgba(0, 0, 0, 0.25)); 
	position: relative;
	margin-left: 14.29px;
	cursor: url("/source/cursor_pointer.png"), pointer;

	${({ theme }: { theme: ThemeType }) => theme.breakPoint.tablet`
		width: 35px;
		height: 35px; 
	`};

	${({ theme }: { theme: ThemeType }) => theme.breakPoint.mobile`
		width: 29px;
		height: 29px;
		margin-left: 0.56vw;
	`};
`;

const Menu = styled.button`
	width: fit-content;
	height: fit-content;
	margin-left: 10px;
`;