import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import Image from 'next/image';
import { ThemeType } from '@src/types/theme';

export type HeaderLogoProps = {
	transparent?: boolean;
};

function HeaderLogo(props: HeaderLogoProps)
{
	return (
		<Wrapper>
			<Link href="/1/soritown">
				<LogoWarpper>
					<Image
						src="/source/logo.png"
						fill={ true }
						alt=""
					/>
				</LogoWarpper>
			</Link>
		</Wrapper>
	);
}

export default React.memo(HeaderLogo);

const Wrapper = styled.div`
	height: fit-content;
	display: flex;
	align-items: center;
	margin: 11px 26px 0 0;

	${({ theme }: { theme: ThemeType }) => theme.breakPoint.tablet`
		margin: 22px 18px 0 0;
	`};

	${({ theme }: { theme: ThemeType }) => theme.breakPoint.mobile`
		margin-top: 7px;
	`};
`;

const LogoWarpper = styled.div`
	width: 91px;
	height: 68px;
	position: relative;

	${({ theme }: { theme: ThemeType }) => theme.breakPoint.tablet`
		width: 63px;
		height: 47px;
	`};

	${({ theme }: { theme: ThemeType }) => theme.breakPoint.mobile`
		width: 50px;
		height: 37.5px;
	`};
`;