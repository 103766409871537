import { IconProps } from "@src/types/IconProps";

export default function Plus(props: IconProps)
{
    const width = props.width ? props.width : 20;
    const height = props.height ? props.height : 3;
    const fill = props.fill ? props.fill : "white";

    return (
        <svg width={ width } height={ height } viewBox="0 0 20 3" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={ props.onClick } style={ props.style }>
            <path d="M1.04167 0.105713C0.46637 0.105713 0 0.572083 0 1.14738C0 1.72268 0.46637 2.18905 1.04167 2.18905H18.9583C19.5336 2.18905 20 1.72268 20 1.14738C20 0.572083 19.5336 0.105713 18.9583 0.105713H1.04167Z" fill={ fill }/>
        </svg> 
    );
}