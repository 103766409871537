import { IconProps } from "@src/types/IconProps";

export default function X(props: IconProps)
{
    const width = props.width ? props.width : 19;
    const height = props.height ? props.height : 18;
    const fill = props.fill ? props.fill : "none";

    return (
        <svg width={ width } height={ height } viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={ props.onClick } style={ props.style }>
            <path d="M0.366116 0.366117C0.854272 -0.122039 1.64573 -0.122039 2.13388 0.366116L9.09555 7.32778L15.9381 0.485273C16.4262 -0.0028822 17.2177 -0.00288237 17.7058 0.485273C18.194 0.973428 18.194 1.76488 17.7058 2.25304L10.8633 9.09555L17.3367 15.5689C17.8248 16.0571 17.8248 16.8485 17.3367 17.3367C16.8485 17.8248 16.0571 17.8248 15.5689 17.3367L9.09555 10.8633L2.50303 17.4558C2.01487 17.944 1.22342 17.944 0.735263 17.4558C0.247108 16.9677 0.247107 16.1762 0.735262 15.6881L7.32778 9.09555L0.366117 2.13388C-0.122039 1.64573 -0.122039 0.854272 0.366116 0.366117Z" fill={ fill }/>
        </svg> 
    );
}