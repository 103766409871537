import { IconProps } from "@src/types/IconProps";

export default function YoutubeIcon(props: IconProps)
{
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={ props.width } height={ props.height } viewBox="0 0 105 105" transform={ `scale(${props.scale})` }>
            <path fill="#FFFFFF" d="M84.68,12.54H20.32c-9.84,0-17.82,10-17.82,19.84v40.24c0,9.84,7.98,19.84,17.82,19.84h64.35
                c9.84,0,17.82-10,17.82-19.84V32.38C102.5,22.54,94.52,12.54,84.68,12.54z M69.75,54.49l-11.73,6.77L46.3,68.04
                c-1.69,0.98-3.81-0.24-3.81-2.2V52.3V38.76c0-1.95,2.11-3.17,3.81-2.2l11.73,6.77l11.73,6.77C71.44,51.08,71.44,53.52,69.75,54.49z"
            />
        </svg>
    );
}