import { Drawer } from "@mui/material";
import { getCookie } from "@src/lib/util/cookie";
import { RootState } from "@src/store";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import HeaderLogo from "../HeaderLogo";
import { Minus, Plus, X } from "@src/components/icons";

export type MobileMenuDrawerProps = {
    menuOpen: boolean;
    setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
};

export default function MobileMenuDrawer(props: MobileMenuDrawerProps)
{
    const router = useRouter();

    const [ login, setLogin ] = useState<boolean>(!!getCookie("accessToken"));
    const [ current, setCurrent ] = useState(0);
    const [ row1, setRow1 ] = useState(false);
    const [ row2, setRow2 ] = useState(false);
    const [ row3, setRow3 ] = useState(false);
    const [ row4, setRow4 ] = useState(false);
    
    function handleCategorySelect(select: number)
    {
        if (current === select)
            setCurrent(0);
        else
            setCurrent(select);

        if (select === 1)
        {
            setRow1(!row1);
            setRow2(false);
            setRow3(false);
            setRow4(false);
        }

        if (select === 2)
        {
            setRow1(false);
            setRow2(!row2);
            setRow3(false);
            setRow4(false);
        }

        if (select === 3)
        {
            setRow1(false);
            setRow2(false);
            setRow3(!row3);
            setRow4(false);
        }

        if (select === 4)
        {
            setRow1(false);
            setRow2(false);
            setRow3(false);
            setRow4(!row4);
        }
    }

    useEffect(() => {
        let select: number = Number(router.pathname.split("/")[1]);

        setCurrent(select);

        if (select === 1)
            setRow1(true)
        if (select === 2)
            setRow2(true)
        if (select === 3)
            setRow3(true)
        if (select === 4)
            setRow4(true)
    }, []);

    return (
        <Drawer
            PaperProps={
                { sx: { width: "100%", height: "100%", color: "white", backgroundColor: "#00B0F0" }}
            }
            anchor="right"
            open={ props.menuOpen }
            onClose={ () => props.setMenuOpen(false) }
        >
            <TopWrapper>
                <HeaderLogo />
                <X
                    style={{ cursor: `url("/source/cursor_pointer.png"), pointer`, marginTop: 16 }}
                    width={ 18 }
                    height={ 18 }
                    fill="#00B0F0"
                    onClick={ () => props.setMenuOpen(false) }
                />
            </TopWrapper>
            <MenuWrap>
                <Row>
                    <Link href="/1/soritown">
                        <Category select={ current === 1 }>소리마을 따꿍이</Category>
                    </Link>
                    <IconWrap onClick={ () => handleCategorySelect(1) }>
                        { row1 ?
                            <Minus />
                            :
                            <Plus />
                        }
                    </IconWrap>
                </Row>
                <Line />
                { row1 &&
                    <SubRow>
                        <Link href="/1/1/culturaltrip">
                            <SubCategory>따꿍이와 문화여행</SubCategory>
                        </Link>
                        <Link href="/1/2/friends">
                            <SubCategory>소리마을 친구들</SubCategory>
                        </Link>
                        <Link href="/1/3/tidings">
                            <SubCategory>따꿍이 소식</SubCategory>
                        </Link>
                    </SubRow>
                }
                <Row>
                    <Link href="/2/traditional">
                        <Category select={ current === 2 }>어린이 전통문화</Category>
                    </Link>
                    <IconWrap onClick={ () => handleCategorySelect(2) }>
                        { row2 ?
                            <Minus />
                            :
                            <Plus />
                        }
                    </IconWrap>
                </Row>
                <Line />
                { row2 &&
                    <SubRow>
                        <Link href="/2/traditional">
                            <SubCategory>어린이 전통문화</SubCategory>
                        </Link>
                        <Link href="/2/1/contents">
                            <SubCategory>콘텐츠 소개</SubCategory>
                        </Link>
                    </SubRow>
                }
                <Row>
                    <Link href="/3/store">
                        <Category select={ current === 3 }>스토어</Category>
                    </Link>
                    <IconWrap onClick={ () => handleCategorySelect(3) }>
                        { row3 ?
                            <Minus />
                            :
                            <Plus />
                        }
                    </IconWrap> 
                </Row>
                <Line />
                <Row>
                    <Link href="/4/inquiry">
                        <Category select={ current === 4 }>문의</Category>
                    </Link>
                    <IconWrap onClick={ () => handleCategorySelect(4) }>
                        { row4 ?
                            <Minus />
                            :
                            <Plus />
                        }
                    </IconWrap>
                </Row>
                <Line />
                { row4 &&
                    <SubRow>
                        <Link href="/4/inquiry">
                            <SubCategory>문의</SubCategory>
                        </Link>
                        <Link href="/4/1/notice">
                            <SubCategory>공지사항</SubCategory>
                        </Link>
                    </SubRow>
                }
            </MenuWrap>
        </Drawer>
    );
}

const TopWrapper = styled.div`
    width: 100%;
    height: 51px;
    background-color: #FFF;
    padding: 0 17px 0 13px;
    display: flex;
    justify-content: space-between;
    user-select: none;
`;

const Category = styled.p<{ select: boolean }>`
    padding: 24px 0 22px 35px;
    font-size: 24px;
    line-height: 1; 
    color: ${ props => props.select ? "#FFE85D" : "#fff" };
`;

const SubCategory = styled.p`
    font-size: 18px;
    line-height: 1; 
    color: #fff;
    margin: 11px 0;
`;

const MenuWrap = styled.div`
    
`;

const Row = styled.div`
    width: 100%;
    height: 71px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
        margin: 0 17px;
    };

    a {
        flex: 9;
    };
`;

const SubRow = styled.div`
    background-color: #0592D0;
    padding: 15px 48px 15px;
`;

const IconWrap = styled.div`
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
`;

const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: #56CBFD;
`;