import React, { useState } from 'react';
import styled from 'styled-components';
import cssMixin from '@styles/cssMixin';
import HeaderLogo from './HeaderLogo';
import CenterButtons from './desktop/centerbuttons/CenterButtons';
import Navigation from './desktop/navigation/Navigation';
import { useRouter } from 'next/router';
import { useIsDesktop, useIsMobile } from '@src/hooks/util';
import { ThemeType } from '@src/types/theme';

export type HeaderProps = {
	backgroundColor?: string;
	coloredMenu?: boolean;
};

function Header(props: HeaderProps)
{
	const router = useRouter();
	const isDesktop = useIsDesktop();
	const isMobile = useIsMobile();

	const [ isHover, setIsHover ] = useState(false);

	return (
		<Wrapper isHover={ isHover } backgroundColor={ props.backgroundColor }>
			<HeaderLogo />
			{ !isMobile && router.pathname !== "/"  &&
				<CenterButtons isHover={ isHover } setIsHover={ setIsHover } />
			}
			<Navigation coloredMenu={ !!props.coloredMenu } />
		</Wrapper>
	);
}

export default React.memo(Header);

const Wrapper = styled.header<{ isHover: boolean, backgroundColor?: string }>`
	${ cssMixin.container };
	${ cssMixin.flexRow };
	height: fit-content;
	position: absolute;
	padding: 0 18px;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	align-items: start;
	background-color: ${ props => props.isHover ? "white" : "transparent" };
	border-radius: 0 0px 20px 20px; 
	z-index: 2;

	${({ theme }: { theme: ThemeType }) => theme.breakPoint.tablet`
		padding: 0 28px;
	`};

	${({ theme, backgroundColor }: { theme: ThemeType, backgroundColor?: string }) => theme.breakPoint.mobile!`
		height: 51px;
		padding: 0 13px;
		background-color: ${ backgroundColor ? backgroundColor : "unset" };
		border-radius: 0;
	`};
`;
