import { IconProps } from "@src/types/IconProps";

export default function ArrowRightMobile(props: IconProps)
{
    const width: number = props.width ? props.width : 20;
    const height: number = props.height ? props.height : 20;

    return (
        <svg width="13" height="21" viewBox="0 0 13 21" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={ props.onClick } style={ props.style }>
            <path d="M3.7081 1.23133C3.02585 0.55079 1.92151 0.550789 1.23926 1.23133C0.55512 1.91377 0.554517 3.02184 1.23792 3.70502L8.03509 10.5L1.23791 17.295C0.554516 17.9782 0.55512 19.0862 1.23926 19.7687C1.92151 20.4492 3.02585 20.4492 3.7081 19.7687L11.5805 11.916C12.3639 11.1345 12.3639 9.86547 11.5805 9.08402L3.7081 1.23133Z" fill="black"/>
        </svg> 
    );
}