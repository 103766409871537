import { IconProps } from "@src/types/IconProps";

export default function InstagramIcon(props: IconProps)
{
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={ props.width } height={ props.height } viewBox="0 0 105 105" transform={ `scale(${props.scale})` }>
            <g>
                <circle fill="#FFFFFF" cx="52.5" cy="52.5" r="17.53"/>
                <path fill="#FFFFFF" d="M77.35,2.5H27.65C13.76,2.5,2.5,13.76,2.5,27.65v49.71c0,13.89,11.26,25.15,25.15,25.15h49.71
                    c13.89,0,25.15-11.26,25.15-25.15V27.65C102.5,13.76,91.24,2.5,77.35,2.5z M52.5,80.14c-15.26,0-27.64-12.37-27.64-27.64
                    c0-15.26,12.37-27.64,27.64-27.64c15.26,0,27.64,12.37,27.64,27.64C80.14,67.76,67.76,80.14,52.5,80.14z M85.88,24.73
                    c-3.09,0-5.59-2.5-5.59-5.59s2.5-5.59,5.59-5.59s5.59,2.5,5.59,5.59S88.97,24.73,85.88,24.73z"/>
            </g>
        </svg>
    );
}