import { breakPoint } from '@src/theme/media';
import _ from 'lodash';
import { Router } from 'next/router';
import { useState, useEffect } from 'react';

const isDev = process.env.NODE_ENV === 'development';

//사용하기 전에 process.browser 확인 후 사용할 것
export const useWindowWidth = () => {
	const [width, setWidth] = useState(window.innerWidth);
	useEffect(() => {
		const handleResize = _.throttle(() => setWidth(window.innerWidth), 300);
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});
	return width;
};

export const useWindowHeight = () => {
	const [height, setHeight] = useState(window.innerHeight);
	useEffect(() => {
		const handleResize = _.throttle(() => setHeight(window.innerHeight), 300);
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});
	return height;
};

export const useScrollDirection = (initialDirection: string) => {
	const [direction, setDirection] = useState(initialDirection);
	const [position, setPosition] = useState(0);
	useEffect(() => {
		const handleScroll = _.throttle(() => {
			if (Math.abs(position - window.scrollY) > 0) {
				setDirection(position > window.scrollY ? 'up' : 'down');
				setPosition(window.scrollY);
			}
		}, 300);
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	});
	return direction;
};

export const useScrollY = () => {
	const [scrollY, setScrollY] = useState(0);
	useEffect(() => {
		const handleScroll = _.throttle(() => {
			setScrollY(window.scrollY);
		}, 300);
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	});
	return scrollY;
};

export const useResize = (myRef: any) => {
	const [width, setWidth] = useState(0);
	const [height, setHeight] = useState(0);

	useEffect(() => {
		const handleResize = _.throttle(() => {
			setWidth(myRef.current.offsetWidth);
			setHeight(myRef.current.offsetHeight);
		}, 300);
		handleResize();
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [myRef]);

	return { width, height };
};

export const useIsDesktop = () => {
	const [isDesktop, setIsDesktop] = useState<boolean>(false);
	useEffect(() => {
		const handleResize = _.throttle(() => {
			if (!process.browser)
				return;
				
			const { innerWidth } = window;

			if (innerWidth > breakPoint.tablet)
				setIsDesktop(true);
			else 
				setIsDesktop(false);
			
		}, 300);
		handleResize();
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return isDesktop;
};

export const useIsTablet = () => {
	const [isTablet, setIsTablet] = useState<boolean>(false);
	useEffect(() => {
		const handleResize = _.throttle(() => {
			if (!process.browser)
				return;
				
			const { innerWidth } = window;

			if (breakPoint.mobile < innerWidth && innerWidth <= breakPoint.tablet)
				setIsTablet(true);
			else 
				setIsTablet(false);
			
		}, 300);
		handleResize();
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return isTablet;
};


export const useIsMobile = () => {
	const [isMoblie, setIsMoblie] = useState<boolean>(false);
	useEffect(() => {
		const handleResize = _.throttle(() => {
			if (!process.browser)
				return;

			const { innerWidth } = window;
			if (innerWidth <= breakPoint.mobile) 
				setIsMoblie(true);
			else
				setIsMoblie(false);
		}, 300);
		handleResize();
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
	
	return isMoblie;
};

// export const useDeviceLevel = () => {
// 	const [level, setLevel] = useState<BreakPointLabel>("labtopL");
// 	useEffect(() => {
// 		const handleResize = _.throttle(() => {
// 			if (!process.browser)
// 				return;

// 			const { innerWidth } = window;

// 			if (innerWidth >= BreakPoint.labtopL)
// 				setLevel("labtopL");
// 			else if (BreakPoint.labtop < innerWidth && innerWidth <= BreakPoint.labtopL)
// 				setLevel("labtop");
// 			else if (BreakPoint.xlargeTablet < innerWidth && innerWidth <= BreakPoint.labtop)
// 				setLevel("xlargeTablet");
// 			else if (BreakPoint.largeTablet < innerWidth && innerWidth <= BreakPoint.xlargeTablet)
// 				setLevel("largeTablet");
// 			else if (BreakPoint.tablet < innerWidth && innerWidth <= BreakPoint.largeTablet)
// 				setLevel("tablet");
// 			else if (BreakPoint.smallTablet < innerWidth && innerWidth <= BreakPoint.tablet)
// 				setLevel("smallTablet");
// 			else if (BreakPoint.mobile < innerWidth && innerWidth <= BreakPoint.smallTablet)
// 				setLevel("mobile");
// 			else if (BreakPoint.mediumMobile < innerWidth && innerWidth <= BreakPoint.mobile)
// 				setLevel("mediumMobile");
// 			else
// 				setLevel("smallMobile");
// 		}, 300);
// 		handleResize();
// 		window.addEventListener('resize', handleResize);

// 		return () => {
// 			window.removeEventListener('resize', handleResize);
// 		};
// 	}, []);
	
// 	return level;
// };

// export const useGtmPageView = () => {
// 	useEffect(() => {
// 		if (!process.browser || isDev) return;

// 		const handleRouteChange = (url: string) => {
// 			gtmPageView(url);
// 		};

// 		Router.events.on('routeChangeComplete', handleRouteChange);
// 		return () => {
// 			Router.events.off('routeChangeComplete', handleRouteChange);
// 		};
// 	}, []);
// };

export const useAudio = (url: string) => {
	const [audio] = useState(new Audio(url));
	const [playing, setPlaying] = useState(false);
  
	const toggle = () => setPlaying(!playing);
  
	useEffect(() => {
		playing ? audio.play() : audio.pause();
	}, [playing]);
  
	useEffect(() => {
		audio.addEventListener('ended', () => setPlaying(false));
		
		return () => {
			audio.removeEventListener('ended', () => setPlaying(false));
		};
	}, []);
  
	return [playing, toggle];
};