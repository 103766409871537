import { IconProps } from "@src/types/IconProps";

export default function Plus(props: IconProps)
{
    const width = props.width ? props.width : 20;
    const height = props.height ? props.height : 20;
    const fill = props.fill ? props.fill : "white";

    return (
        <svg width={ width } height={ height } viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={ props.onClick } style={ props.style }>
            <path d="M10.2877 20C9.71242 20 9.24605 19.5336 9.24605 18.9583L9.24605 11.189H1.04167C0.46637 11.189 0 10.7226 0 10.1473C0 9.57201 0.46637 9.10564 1.04167 9.10564H9.24605L9.24605 1.04167C9.24605 0.466372 9.71242 0 10.2877 0C10.863 0 11.3294 0.466371 11.3294 1.04167L11.3294 9.10564H18.9583C19.5336 9.10564 20 9.57201 20 10.1473C20 10.7226 19.5336 11.189 18.9583 11.189H11.3294L11.3294 18.9583C11.3294 19.5336 10.863 20 10.2877 20Z" fill={ fill }/>
        </svg>  
    );
}