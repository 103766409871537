import { IconProps } from "@src/types/IconProps";
import { forwardRef } from "react";

function ArrowRight(props: IconProps, ref: any)
{
    return (
        <div style={ props.style } onClick={ props.onClick } ref={ ref }>
            <svg width="20" height="33" viewBox="0 0 20 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.88193 1.52328L18.8355 13.5847C20.3883 15.1516 20.3883 17.6856 18.8355 19.2525L6.88193 31.314C5.33296 32.8769 2.81785 32.8769 1.26889 31.314C-0.280081 29.7471 -0.280081 27.2133 1.26889 25.6464L10.4139 16.4186L1.26889 7.19085C0.494403 6.40543 0.106934 5.38337 0.106934 4.35718L1.26889 1.52328L4.0772 0.351074C5.09028 0.351074 6.10744 0.741801 6.88193 1.52328Z" fill="black"/>
            </svg> 
        </div>
    );
}

export default forwardRef(ArrowRight);