import { ThemeType } from "@src/types/theme";
import Link from "next/link";
import { useRef, useState } from "react";
import styled from "styled-components"

export type CenterButtonsProps = {
    isHover: boolean
    setIsHover: (value: boolean) => void;
}

export default function CenterButtons(props: CenterButtonsProps)
{
    const hoverDiv = useRef<HTMLDivElement>(null);

    const [ hoverNum, setHoverNum ] = useState(-1);

    const ctg1 = [ 
        { href: "/1/1/culturaltrip", text: "따꿍이와 문화여행" },
        { href: "/1/2/friends", text: "소리마을 친구들" },
        { href: "/1/3/tidings", text: "따꿍이 소식" },
    ]

    const ctg2 = [ 
        { href: "/2/traditional", text: "어린이 전통문화" },
        { href: "/2/1/contents", text: "콘텐츠 소개" },
    ]

    const ctg3 = [ 

    ]

    const ctg4 = [ 
        { href: "/4/inquiry", text: "문의" },
        { href: "/4/1/notice", text: "공지사항" },
    ]

    function handleMouseOver()
    {
        props.setIsHover(true);
    }

    function handleMouseOut()
    {
        props.setIsHover(false);
        setHoverNum(-1);
    }

    return (
        <Wrapper>
            <HoverDiv onMouseOver={ handleMouseOver } onMouseOut={ handleMouseOut }>
                <Category>
                    <Link href="/1/soritown">
                        <StyledP1 isHover={ hoverNum === 0 } onMouseOver={ () => setHoverNum(0) }>
                            소리마을 따꿍이
                        </StyledP1>
                    </Link>
                    <SubCategory>
                        { props.isHover && ctg1.map((data, index) => 
                            <Link href={ data.href } key={ `ctg1_${index}` }>
                                <StyledP2>{ data.text }</StyledP2>
                            </Link>
                        )}
                    </SubCategory>
                </Category>
                <Category>
                    <Link href="/2/traditional">
                        <StyledP1 isHover={ hoverNum === 1 } onMouseOver={ () => setHoverNum(1) }>
                            어린이 전통문화
                        </StyledP1>
                    </Link>
                    <SubCategory>
                        { props.isHover && ctg2.map((data, index) => 
                            <Link href={ data.href } key={ `ctg2_${index}` }>
                                <StyledP2>{ data.text }</StyledP2>
                            </Link>
                        )}
                    </SubCategory>
                </Category>
                <Category>
                    <Link href="/3/store">
                        <StyledP1 isHover={ hoverNum === 2 } onMouseOver={ () => setHoverNum(2) }>
                            스토어
                        </StyledP1>
                    </Link>
                </Category>
                <Category>
                    <Link href="/4/inquiry">
                        <StyledP1 isHover={ hoverNum === 3 } onMouseOver={ () => setHoverNum(3) }>
                            문의
                        </StyledP1>
                    </Link>
                    <SubCategory>
                        { props.isHover && ctg4.map((data, index) => 
                            <Link href={ data.href } key={ `ctg4_${index}` }>
                                <StyledP2>{ data.text }</StyledP2>
                            </Link>
                        )}
                    </SubCategory>
                </Category>
            </HoverDiv>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    
`;

const HoverDiv = styled.div`
    width: fit-content;
    display: flex;
    align-items: start;
`;

const Category = styled.div`
    width: fit-content;
    min-width: 60px;
    margin: 25px 15px;
    text-align: center;

    ${({ theme }: { theme: ThemeType }) => theme.breakPoint.tablet`
        margin: 25px 10px;
    `};
`;

const SubCategory = styled.div`
    width: 100%;
`;

const StyledP1 = styled.p<{ isHover?: boolean }>`
    width: fit-content;
    height: 50px;
    margin: 0 auto 15px;
    font-size: 24px;
    font-weight: bold;
    text-shadow: -0.14vw 0 #02A3E9, 0 0.14vw #02A3E9, 0.14vw 0 #02A3E9, 0 -0.14vw #02A3E9, -0.1vw 0.1vw #02A3E9, -0.1vw -0.1vw #02A3E9, 0.1vw 0.1vw #02A3E9, 0.1vw -0.1vw #02A3E9;
    color: ${ ({ isHover, theme }) => isHover ? theme.themeColor.Textyellow : "white" };
    word-break: keep-all;
    position: relative;
    display: flex;
    align-items: center;
    top: 50%;
    cursor: url("/source/cursor_pointer.png"), pointer;

    ${({ theme }: { theme: ThemeType }) => theme.breakPoint.tablet`
        font-size: 22px;
    `};
`;

const StyledP2 = styled.p`
    width: fit-content;
    margin: 0 auto;
    font-size: 18px;
    line-height: 27px;
    color: ${ ({ theme }: { theme: ThemeType }) => theme.themeColor.text };
    word-break: keep-all;
    cursor: url("/source/cursor_pointer.png"), pointer;
`;

const UnderBar = styled.div<{ width: number, left: number, visible: boolean }>`
    width: ${ props => props.width }px;
    height:  0.28vw; 
    position: absolute;
    background: #F3B25A; 
    bottom: -8px;
    left: ${ props => props.left }px;
    display: ${ props => props.visible ? "initial" : "none" };
`;