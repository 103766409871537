import React from 'react';
import styled from 'styled-components';

import FooterInfo from './info/FooterInfo';
import cssMixin from '@styles/cssMixin';
import { ThemeType } from '@src/types/theme';
import { useIsDesktop, useIsMobile } from '@src/hooks/util';

export type FooterProps = {
	backgroundColor?: string;
	footerColor?: string;
};

export default function Footer(props: FooterProps)
{
	const isMobile = useIsMobile();

	return (
		<Background backgroundColor={ props.backgroundColor } >
			<Wrapper>
				<FooterInfo footerColor={ !isMobile ? "" : props.footerColor } />
			</Wrapper>
		</Background>
	);
}
const Background = styled.div<{ backgroundColor?: string }>`
	width: 100%;
	height: 214px;
	background-color: ${ props => props.backgroundColor ? props.backgroundColor : "#fff" };

	${({ theme }: { theme: ThemeType }) => theme.breakPoint.mobile`
		width: 100%;
		height: 19vw;
		position: absolute;
		bottom: 0;
		background-color: transparent;
		padding: 5vw 0;
		margin-bottom: 40px;
	`}
`;

const Wrapper = styled.div`
	${ cssMixin.container };
	${ cssMixin.flexRow };
	width: 100%;
	height: 100%;
	align-items: flex-start;
	justify-content: space-between;
`;