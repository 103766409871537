import { IconProps } from "@src/types/IconProps";

export default function SignupIcon(props: IconProps)
{
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 105 105" enableBackground="new 0 0 105 105" xmlSpace="preserve">
            <g>
                <path fill="#00B0F0" d="M40.58,55.85c0,0-8.92,13.86-2.15,32.25H2.52V71.98C2.52,71.98,14.54,56.09,40.58,55.85z"/>
                <circle fill="#00B0F0" cx="45.23" cy="21.53" r="19.03"/>
                <path fill="#00B0F0" d="M73.79,45.11c-15.85,0-28.69,12.85-28.69,28.69s12.85,28.69,28.69,28.69s28.69-12.85,28.69-28.69
                    S89.63,45.11,73.79,45.11z M88.04,78.58h-9.47v9.47h-9.56v-9.47h-9.47v-9.56h9.47v-9.47h9.56v9.47h9.47V78.58z"/>
            </g>
        </svg> 
    );
}