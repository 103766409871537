import { IconProps } from "@src/types/IconProps";

export default function BlogIcon(props: IconProps)
{
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={ props.width } height={ props.height } viewBox="0 0 105 105" transform={ `scale(${props.scale})` }>
            <g>
                <path fill="#FFFFFF" d="M76.07,7.34c3.61,0,7.23,0,10.84,0c0.71,0.11,1.42,0.21,2.13,0.37c1.11,0.24,2.18,0.59,3.21,1.03
                    c1.61,0.69,3.08,1.61,4.42,2.74c1.4,1.18,2.59,2.56,3.58,4.11c0.83,1.31,1.47,2.71,1.93,4.18c0.1,0.32,0.12,0.66,0.31,0.95
                    c0,16.3,0,32.6,0,48.91c-0.16,0.21-0.17,0.47-0.24,0.71c-0.76,2.65-2.04,5-3.9,7.03c-1.19,1.3-2.56,2.38-4.08,3.26
                    c-1.8,1.05-3.75,1.71-5.8,2.09c-1.09,0.21-2.2,0.28-3.31,0.28C79.19,83.02,73.2,83.03,67.22,83c-1.16-0.01-2.31-0.04-3.47-0.04
                    c-0.96,0-1.77,0.32-2.28,1.18c-0.14,0.24-0.27,0.49-0.41,0.74c-1.05,1.84-2.09,3.67-3.15,5.51c-0.75,1.3-1.5,2.61-2.27,3.9
                    c-0.51,0.85-0.96,1.74-1.58,2.53c-0.74,0.95-1.9,1.12-2.8,0.39c-0.33-0.27-0.61-0.59-0.82-0.96c-1.28-2.2-2.56-4.4-3.84-6.61
                    c-0.95-1.64-1.86-3.29-2.78-4.94c-0.32-0.58-0.74-1.08-1.35-1.41c-0.48-0.26-0.99-0.35-1.52-0.34C39.47,82.96,37.98,83,36.5,83
                    c-5.5,0-11.01,0.08-16.51-0.03c-1.26-0.03-2.51-0.06-3.75-0.31c-0.88-0.18-1.76-0.39-2.61-0.69c-2.73-0.96-5.08-2.51-7.03-4.65
                    c-1.52-1.67-2.65-3.57-3.42-5.69c-0.44-1.22-0.67-2.45-0.67-3.77c0.02-15.31,0.02-30.62,0.02-45.94c0-0.28,0-0.56,0.01-0.85
                    c0-0.1,0-0.21,0.03-0.31c0.3-1.21,0.69-2.4,1.23-3.53c1.38-2.91,3.42-5.24,6.1-7.02c1.26-0.84,2.61-1.5,4.04-1.96
                    c1.09-0.35,2.2-0.61,3.34-0.77c0.42-0.06,0.85-0.03,1.26-0.14c16.4,0,32.79,0,49.19,0c0.13,0.07,0.27,0.05,0.41,0.05
                    c2.51,0,5.02,0,7.53,0C75.79,7.39,75.94,7.4,76.07,7.34z M89.88,47.12c0-2.67,0-5.35,0-8.02c0-0.22,0.01-0.43-0.01-0.65
                    c-0.03-0.36-0.18-0.63-0.58-0.74c-0.38-0.1-0.76-0.14-1.14-0.16c-0.85-0.05-1.69,0.08-2.54,0.06c-0.28-0.01-0.48,0.16-0.62,0.38
                    c-0.12,0.18-0.2,0.38-0.31,0.57c-0.19,0.32-0.41,0.38-0.73,0.19c-0.16-0.1-0.31-0.21-0.46-0.32c-0.94-0.66-1.98-1.09-3.12-1.19
                    c-1.43-0.12-2.85,0.02-4.23,0.48c-2.16,0.72-3.82,2.06-4.9,4.08c-0.52,0.97-0.86,2-0.99,3.09c-0.1,0.79-0.11,1.59-0.07,2.39
                    c0.07,1.23,0.36,2.4,0.89,3.5c0.78,1.6,1.97,2.82,3.51,3.71c1.16,0.66,2.39,1.13,3.7,1.28c1.5,0.17,2.94-0.1,4.28-0.83
                    c0.49-0.27,0.92-0.62,1.4-0.89c0.39-0.22,0.67-0.11,0.76,0.32c0.27,1.24,0.01,2.37-0.77,3.37c-0.54,0.69-1.28,1.09-2.09,1.39
                    c-0.36,0.13-0.61,0.36-0.8,0.67c-0.62,1.04-0.74,2.18-0.63,3.35c0.06,0.56,0.42,0.83,0.99,0.83c0.19,0,0.36-0.09,0.55-0.1
                    c0.85-0.03,1.67-0.23,2.47-0.53c1.52-0.57,2.79-1.47,3.75-2.78c1.14-1.55,1.65-3.33,1.68-5.23C89.91,52.6,89.88,49.86,89.88,47.12z
                    M15.15,43.89c0,2.67,0,5.35,0,8.02c0,1.09-0.01,2.18,0,3.27c0,0.45,0.23,0.75,0.6,0.81c0.27,0.04,0.54,0.07,0.81,0.08
                    c0.76,0.01,1.52,0,2.29,0.01c0.34,0,0.68-0.03,1.01-0.07c0.29-0.04,0.52-0.25,0.56-0.52c0.11-0.83,0.42-0.95,1.09-0.45
                    c0.01,0.01,0.01,0.01,0.02,0.02c1.31,0.95,2.79,1.38,4.4,1.45c0.3,0.01,0.58-0.07,0.86-0.1c0.89-0.07,1.73-0.31,2.56-0.63
                    c1.86-0.74,3.29-1.97,4.27-3.72c0.79-1.42,1.16-2.97,1.3-4.57c0.11-1.32,0.02-2.65-0.38-3.93c-0.36-1.13-0.91-2.15-1.67-3.08
                    c-0.68-0.83-1.5-1.5-2.39-2.09c-0.99-0.65-2.09-1.09-3.26-1.26c-0.84-0.12-1.7-0.11-2.54,0.07c-1.04,0.22-2.01,0.6-2.93,1.13
                    c-0.24,0.14-0.46,0.29-0.73,0.36c-0.22,0.05-0.42-0.04-0.45-0.23c-0.06-0.45-0.1-0.91-0.07-1.37c0.03-0.52,0.08-1.04,0-1.55
                    c-0.11-0.72-0.11-1.45-0.03-2.16c0.05-0.46-0.06-0.89-0.06-1.33c0-0.25-0.18-0.44-0.43-0.51c-0.25-0.07-0.52-0.13-0.78-0.14
                    c-0.92-0.02-1.84-0.02-2.77-0.01c-0.78,0.01-1.26,0.49-1.29,1.28c-0.02,0.47,0,0.94,0,1.41C15.15,37.36,15.15,40.63,15.15,43.89z
                    M57.48,56.41c0.52-0.02,1.03-0.02,1.55-0.1c0.53-0.09,1.06-0.18,1.56-0.35c1.4-0.47,2.67-1.19,3.73-2.25
                    c1.83-1.81,2.71-4.02,2.85-6.55c0.05-0.97-0.08-1.93-0.3-2.87c-0.44-1.85-1.34-3.43-2.77-4.71c-1.68-1.5-3.69-2.16-5.88-2.35
                    c-1.16-0.1-2.3,0.04-3.43,0.32c-1.27,0.31-2.42,0.86-3.45,1.66c-2.44,1.89-3.5,4.46-3.63,7.46c-0.04,0.81,0.11,1.62,0.29,2.42
                    c0.42,1.82,1.29,3.39,2.65,4.66C52.55,55.57,54.86,56.4,57.48,56.41z M44.14,42.65c-0.03-0.97,0.03-2.15-0.04-3.32
                    c-0.06-0.87-0.11-1.74-0.35-2.59c-0.34-1.21-0.93-2.28-1.8-3.18c-1.35-1.38-3.01-2.21-4.86-2.7c-0.81-0.21-1.17,0.34-1.26,0.89
                    c-0.15,0.93-0.1,1.88-0.12,2.82c-0.02,0.56,0.25,0.94,0.76,1.16c0.43,0.19,0.83,0.45,1.16,0.79c0.82,0.82,1.16,1.85,1.17,2.97
                    c0.03,4.17,0.02,8.34,0.01,12.5c0,0.4,0.07,0.8,0.03,1.21c-0.05,0.66-0.04,1.34-0.01,2c0.02,0.49,0.26,0.75,0.65,0.8
                    c1.15,0.13,2.31,0.1,3.47,0.05c0.75-0.03,0.96-0.26,1.17-0.99c0.06-0.2,0.04-0.39,0.04-0.59c0-3.09,0-6.19,0-9.28
                    C44.14,44.41,44.14,43.63,44.14,42.65z"/>
                <path fill="#FFFFFF" d="M85.12,46.31c0.01,2.17-1.31,3.97-3.29,4.7c-2.26,0.83-4.91-0.09-6.08-2.14c-0.36-0.63-0.57-1.29-0.63-2.02
                    c-0.1-1.26,0.17-2.4,0.93-3.41c0.58-0.78,1.38-1.25,2.3-1.56c0.82-0.27,1.67-0.3,2.51-0.21c1.93,0.23,3.58,1.36,4.11,3.47
                    C85.08,45.55,85.18,45.96,85.12,46.31z"/>
                <path fill="#FFFFFF" d="M29.89,46.72c0.07,0.97-0.16,1.87-0.63,2.71c-0.69,1.21-1.69,2.01-3.07,2.33c-1.17,0.27-2.3,0.17-3.37-0.33
                    c-1.05-0.49-1.81-1.3-2.23-2.4c-0.68-1.78-0.64-3.54,0.34-5.2c0.62-1.04,1.56-1.68,2.75-1.96c0.52-0.12,1.04-0.24,1.58-0.19
                    c2.22,0.17,3.98,1.39,4.53,3.78C29.89,45.89,29.88,46.3,29.89,46.72z"/>
                <path fill="#FFFFFF" d="M57.36,51.67c-1.84-0.05-3.33-0.83-4.14-2.58c-0.88-1.91-0.73-3.8,0.56-5.5c0.85-1.12,2.04-1.67,3.47-1.72
                    c0.93-0.03,1.79,0.16,2.6,0.62c1.12,0.64,1.81,1.63,2.14,2.85c0.35,1.31,0.26,2.62-0.36,3.84C60.75,50.88,59.3,51.64,57.36,51.67z"
                    />
            </g>
        </svg>
    );
}