import { IconProps } from "@src/types/IconProps";

export default function LoginIcon(props: IconProps)
{
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 105 105" enableBackground="new 0 0 105 105" xmlSpace="preserve">
            <g>
                <path fill="#00B0F0" d="M37.07,72.9L47,62.7l9.93-10.2L47,42.3l-9.93-10.2v15.45H2.5C4.99,22.26,26.37,2.5,52.38,2.5
                    c27.68,0,50.12,22.39,50.12,50c0,27.61-22.44,50-50.12,50c-26.01,0-47.38-19.76-49.88-45.05h34.57V72.9z"/>
            </g>
        </svg> 
    );
}